import React, { createContext, useEffect, useState } from 'react'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setUser(JSON.parse(localStorage.getItem('user')))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))
  }, [user])
  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
}
